export const modalConstants = {
    HIDDEN: 0,
    SIDEMENU: 1,
    LOGIN: 2,
    REGISTER: 3,
    SCENE: "Scenes",
    LIBRARY: "Photo upload",
    ART: "Art",
    EMOJI: "Emojis",
    STICKER: "Stickers",
    PAINT: "Color",
    TEXT: "Text",
    LAYOUT: "LAYOUT",
    CONFIRM: "CONFIRM",
    WARNING: "WARNING",
    OTHER: "OTHER",
};  