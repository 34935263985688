import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Auth } from 'aws-amplify';
import styled from '@emotion/styled';
import { socialProviders } from '../../constants';
import LoadingGif from '/static/gifs/loading.gif';

const SocialLogin = () => {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        const params = new URLSearchParams(window.location.search);
        const provider = params.get('provider');
        const allProviders = Object.values(socialProviders);
        if (allProviders.includes(provider)) {
          Auth.federatedSignIn({ provider });
        } else {
          // invalid url
          navigate('/');
        }
      });
  }, []);

  return (
    <Section className="loading-wrapper">
      <img className="loading-gif large" src={LoadingGif} alt="image loading" />
    </Section>
  );
};

export default SocialLogin;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
