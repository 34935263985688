export const userActionConstants = {
    PICK_COLOR: 0,
    SHOW_GRID: 1,
    DELETE: 2,
    REDO: 3,
    UNDO: 4,
    DELETE_ALL: 5,
    SELECT_LAYER: 10,
    BRING_FRONT: 6,
    BRING_FORWARD: 7,
    SEND_BACK: 8,
    SEND_BACKWARD: 9,
    ROTATE_CUSTOM: 'ROTATE_CUSTOM',
    ZOOM: 'ZOOM',
    ROTATE_LEFT: 12,
    ROTATE_RIGHT: 11,
    ADD_TEXT: 'ADD_TEXT',
    EDIT_TEXT: 'EDIT_TEXT',
    CROP_IMAGE: 'CROP_IMAGE',
};